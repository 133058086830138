<template>
    <div class="workexperience-whole-container">
        <div class="workexperience-title-container">
            <h1>Developer Experience</h1>
        </div>
        <div class="workexperience-list-whole-container">
            <div class="workexperience-list-container">
                <div class="workexperience-list-padding">
                    <div class="workexperience-list-title-container">
                        <h3>Digital Assistant - Bloom Hearing Care - Part Time / Remote</h3>
                    </div>
                    <div class="workexperience-date-container">
                        <p><font-awesome-icon icon="fa-solid fa-calendar-days" />August - December 2020</p>
                    </div>
                    <div class="workexperience-list-resp-container">
                        <h4>Responsibilities</h4>
                        <ul>
                            <li>Created web pages to direct users to an online hearing test as part of Bloom's opticians partnership.</li>
                            <li>Created custom pages on WordPress following a brief from Bloom.</li>
                            <li>Worked remotely during the pandemic.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="workexperience-list-container">
                <div class="workexperience-list-padding">
                    <div class="workexperience-list-title-container">
                        <h3>Freelance Web Developer</h3>
                    </div>
                    <div class="workexperience-date-container">
                        <p><font-awesome-icon icon="fa-solid fa-calendar-days" />January - August 2020</p>
                    </div>
                    <div class="workexperience-list-resp-container">
                        <h4>Responsibilities</h4>
                        <ul>
                            <li>Designed and created websites for three local businesses.</li>
                            <li>Coded custom WordPress websites.</li>
                            <li>Helped clients purchase hosting and domains.</li>
                            <li>Set up the websites on their domain.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </template>
  
  <script>
  export default {
    name: 'WorkExperience',
  }
  </script>