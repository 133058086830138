<template>
    <PortfolioFull></PortfolioFull>
</template>
  
<script>

import PortfolioFull from './PortfolioFull.vue'


  export default {
    name: 'PortfolioView',
    components: {
        PortfolioFull,
    }
  }
</script>