<template>
    <div class="introduction-whole-container">
        <div class="introduction-padding">
            <div class="introduction-image-left-flex">
                <div class="polaroid-white-container">
                    <div class="polaroid-image-container">
                        <img src="/IntroductionImage.png">
                    </div>
                    <div class="polaroid-information-container">
                        <ul>
                            <li><font-awesome-icon icon="fa-solid fa-map-pin" />London</li>
                            <li><font-awesome-icon icon="fa-solid fa-envelope" />lucyisobelscanlon@gmail.com</li>
                            <a href="https://github.com/lucyscanlon" target="_blank"><li><font-awesome-icon icon="fa-brands fa-github" />github.com/lucyscanlon</li></a>
                        </ul>
                    </div>

                </div>

            </div>
            <div class="introduction-text-right-flex">
                <div class="introduction-text-padding">
                    <div class="introduction-heading-container">
                        <h1>Hey!</h1>
                    </div>
                    <div class="introduction-description-container">
                        <p>Welcome to my portfolio website! I am a recent graduate from Goldsmiths University of London, and am currently looking for an entry level developer role. Please look at my projects to see examples of my work.</p>
                    </div>
                    <div class="introduction-grad-container">
                        <p><font-awesome-icon icon="fa-solid fa-user-graduate" /> First Class Honors Degree in Creative Computing</p>
                    </div>
                </div>

            </div>
            

        </div>

    </div>
   
  </template>
  
  <script>
  export default {
    name: 'HelloSection',
  }
  </script>